import React from "react";

function HouseOfCardsLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsVectornator="http://vectornator.io"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 400 400"
    >
      <g fillRule="evenodd" vectornatorLayerName="Layer 1">
        <path
          fill="#141414"
          d="M79.642 250.716c33.616-58.66 47.066-80.261 47.066-80.261"
        ></path>
        <path d="M127.345 166.328a4.134 4.134 0 00-4.166 1.922c-7.079 11.409-13.836 23.032-20.574 34.644-8.852 15.253-17.621 30.563-26.384 45.867a3.95 3.95 0 106.858 3.922 4172.34 4172.34 0 0129.148-50.024c5.946-10.035 11.863-20.09 18.035-29.989a4.198 4.198 0 00-1.348-5.766 4.2 4.2 0 00-1.569-.576z"></path>
        <path
          fill="#141414"
          d="M255.875 148.063c-35.344-58.532-49.442-80.061-49.442-80.061"
        ></path>
        <path d="M205.679 63.894a4.09 4.09 0 00-1.525.612 4.198 4.198 0 00-1.215 5.795c6.469 9.866 12.663 19.901 18.906 29.911a4236.613 4236.613 0 0130.635 49.903 3.948 3.948 0 005.422 1.344 3.984 3.984 0 001.344-5.452c-9.441-15.648-18.875-31.278-28.412-46.867-6.857-11.206-13.734-22.417-20.916-33.419a4.178 4.178 0 00-4.239-1.827z"></path>
        <path
          fill="#141414"
          d="M275.445 350.91c33.616-58.661 47.066-80.261 47.066-80.261"
        ></path>
        <path d="M323.159 266.534a4.18 4.18 0 00-4.197 1.922c-7.079 11.41-13.836 23.032-20.575 34.645-8.851 15.252-17.621 30.531-26.383 45.835a3.95 3.95 0 001.467 5.39c1.894 1.085 4.306.457 5.391-1.437a4172.85 4172.85 0 0129.18-50.024c5.946-10.035 11.831-20.121 18.001-30.02 1.218-1.957.643-4.518-1.315-5.736a4.226 4.226 0 00-1.569-.575z"></path>
        <path
          fill="#141414"
          d="M208.502 248.11c33.616-58.661 47.066-80.262 47.066-80.262"
        ></path>
        <path d="M256.23 163.729a4.186 4.186 0 00-4.198 1.923c-7.079 11.409-13.835 23.032-20.574 34.644-8.851 15.253-17.621 30.532-26.384 45.836-1.084 1.893-.424 4.336 1.468 5.421a3.95 3.95 0 005.391-1.468 4157.214 4157.214 0 0129.18-50.024c5.946-10.035 11.831-20.121 18.001-30.02a4.16 4.16 0 00-1.347-5.735 4.068 4.068 0 00-1.537-.577z"></path>
        <path
          fill="#141414"
          d="M145.031 349.755c33.614-58.661 47.064-80.262 47.064-80.262"
        ></path>
        <path d="M192.762 265.367a4.184 4.184 0 00-4.197 1.923c-7.079 11.41-13.836 23.032-20.573 34.644-8.853 15.253-17.622 30.563-26.385 45.867a3.95 3.95 0 006.858 3.922 4195.217 4195.217 0 0129.148-50.023c5.946-10.036 11.864-20.091 18.035-29.99a4.197 4.197 0 00-1.348-5.766 4.096 4.096 0 00-1.538-.577z"></path>
        <path
          fill="#141414"
          d="M17.304 352.348c33.615-58.66 47.066-80.261 47.066-80.261"
        ></path>
        <path d="M65.033 267.952a4.182 4.182 0 00-4.197 1.924c-7.08 11.409-13.835 23.031-20.574 34.644-8.851 15.252-17.62 30.562-26.383 45.866a3.95 3.95 0 106.857 3.922 4162.639 4162.639 0 0129.18-49.992c5.946-10.036 11.831-20.122 18.003-30.021a4.197 4.197 0 00-1.348-5.766 4.087 4.087 0 00-1.538-.577z"></path>
        <g>
          <path
            fill="#141414"
            d="M142.273 148.996c33.615-58.66 47.065-80.26 47.065-80.26"
          ></path>
          <path d="M189.998 64.605a4.178 4.178 0 00-4.196 1.923c-7.08 11.409-13.836 23.031-20.574 34.643-8.851 15.254-17.622 30.564-26.385 45.868a3.95 3.95 0 001.469 5.389 3.95 3.95 0 005.39-1.467 4165.142 4165.142 0 0129.18-50.025c5.945-10.035 11.831-20.09 18.002-29.989a4.197 4.197 0 00-1.347-5.766 4.085 4.085 0 00-1.539-.576z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M259.784 350.321c-35.344-58.533-49.443-80.061-49.443-80.061"
          ></path>
          <path d="M209.585 266.144a4.074 4.074 0 00-1.525.612 4.197 4.197 0 00-1.215 5.795c6.468 9.867 12.663 19.901 18.906 29.911a4244.129 4244.129 0 0130.666 49.903 3.95 3.95 0 005.422 1.344c1.868-1.128 2.441-3.553 1.313-5.422-9.441-15.647-18.876-31.308-28.412-46.897-6.857-11.206-13.734-22.417-20.916-33.419a4.18 4.18 0 00-4.239-1.827z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M320.984 248.324c-35.344-58.533-49.442-80.061-49.442-80.061"
          ></path>
          <path d="M270.798 164.157a4.234 4.234 0 00-1.556.611c-1.928 1.262-2.445 3.835-1.184 5.764 6.468 9.866 12.663 19.933 18.906 29.942a4228.536 4228.536 0 0130.635 49.904 3.95 3.95 0 005.422 1.344 3.987 3.987 0 001.345-5.453c-9.442-15.648-18.876-31.277-28.414-46.866-6.856-11.207-13.733-22.417-20.914-33.419a4.178 4.178 0 00-4.24-1.827z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M192.484 249.416c-35.343-58.534-49.441-80.063-49.441-80.063"
          ></path>
          <path d="M142.302 165.251a4.223 4.223 0 00-1.555.611c-1.929 1.262-2.446 3.835-1.185 5.764 6.469 9.866 12.663 19.933 18.906 29.942a4223.272 4223.272 0 0130.635 49.903c1.129 1.868 3.554 2.441 5.422 1.313a3.95 3.95 0 001.345-5.422c-9.442-15.647-18.876-31.277-28.414-46.866-6.855-11.206-13.733-22.416-20.914-33.419a4.177 4.177 0 00-4.24-1.826z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M387.527 349.239c-35.344-58.533-49.442-80.061-49.442-80.061"
          ></path>
          <path d="M337.331 265.059a4.082 4.082 0 00-1.525.611 4.198 4.198 0 00-1.215 5.796c6.468 9.866 12.662 19.901 18.906 29.911a4241.786 4241.786 0 0130.635 49.903c1.128 1.868 3.585 2.472 5.453 1.344 1.868-1.128 2.441-3.554 1.313-5.422-9.441-15.647-18.876-31.308-28.412-46.897-6.857-11.207-13.734-22.417-20.916-33.419a4.179 4.179 0 00-4.239-1.827z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M129.764 350.674c-35.344-58.534-49.442-80.062-49.442-80.062"
          ></path>
          <path d="M79.58 266.505a4.21 4.21 0 00-1.555.612c-1.93 1.261-2.446 3.835-1.184 5.764 6.468 9.866 12.662 19.932 18.906 29.942a4221.72 4221.72 0 0130.634 49.903 3.95 3.95 0 005.422 1.344c1.868-1.128 2.472-3.586 1.344-5.453-9.441-15.648-18.875-31.277-28.412-46.866-6.857-11.206-13.734-22.417-20.915-33.419a4.18 4.18 0 00-4.24-1.827z"></path>
        </g>
      </g>
      <g fillRule="evenodd" vectornatorLayerName="Layer 2">
        <path
          fill="#141414"
          d="M181.717 258.384c-68.374.588-94.082 1.75-94.082 1.75"
        ></path>
        <path d="M83.724 258.68a4.217 4.217 0 00-.267 1.65c.103 2.302 2.054 4.059 4.357 3.956 11.785-.539 23.603-.74 35.396-1.012 19.514-.45 39.031-.754 58.549-.934a3.948 3.948 0 003.919-3.98c-.018-2.182-1.825-3.954-4.007-3.935-18.275.15-36.53.305-54.803.568-13.136.19-26.286.396-39.412.969a4.18 4.18 0 00-3.732 2.718z"></path>
        <path
          fill="#141414"
          d="M314.645 257.864c-68.374.589-94.082 1.751-94.082 1.751"
        ></path>
        <path d="M216.652 258.16a4.22 4.22 0 00-.266 1.651c.102 2.302 2.054 4.058 4.356 3.955 11.785-.539 23.603-.739 35.397-1.012 19.513-.45 39.03-.753 58.548-.933a3.948 3.948 0 003.919-3.981c-.018-2.182-1.825-3.954-4.007-3.934-18.275.15-36.53.305-54.803.568-13.136.19-26.286.396-39.412.969a4.18 4.18 0 00-3.732 2.717z"></path>
        <path
          fill="#141414"
          d="M246.748 155.226c-68.375.589-94.082 1.751-94.082 1.751"
        ></path>
        <path d="M148.754 155.522a4.22 4.22 0 00-.266 1.651c.102 2.302 2.054 4.058 4.356 3.955 11.785-.539 23.603-.739 35.397-1.012 19.513-.45 39.031-.753 58.549-.933a3.95 3.95 0 003.919-3.981 3.985 3.985 0 00-4.007-3.934c-18.275.15-36.53.305-54.803.568-13.137.19-26.287.396-39.413.969a4.182 4.182 0 00-3.732 2.717z"></path>
        <g>
          <path
            fill="#141414"
            d="M121.926 358.256c-68.374.588-94.082 1.75-94.082 1.75"
          ></path>
          <path d="M23.933 358.551a4.222 4.222 0 00-.267 1.651c.103 2.302 2.054 4.058 4.357 3.955 11.784-.539 23.603-.739 35.396-1.012 19.514-.45 39.031-.753 58.549-.933a3.948 3.948 0 003.919-3.981c-.018-2.182-1.826-3.954-4.007-3.934-18.275.15-36.53.305-54.803.568-13.136.19-26.286.396-39.412.969a4.18 4.18 0 00-3.732 2.717z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M247.403 358.256c-68.374.588-94.082 1.75-94.082 1.75"
          ></path>
          <path d="M149.41 358.551a4.238 4.238 0 00-.267 1.651c.103 2.302 2.054 4.058 4.357 3.955 11.785-.539 23.603-.739 35.396-1.012 19.514-.45 39.031-.753 58.549-.933a3.948 3.948 0 003.919-3.981c-.018-2.182-1.825-3.954-4.007-3.934-18.275.15-36.53.305-54.803.568-13.136.19-26.286.396-39.412.969a4.18 4.18 0 00-3.732 2.717z"></path>
        </g>
        <g>
          <path
            fill="#141414"
            d="M382.926 358.256c-68.375.588-94.082 1.75-94.082 1.75"
          ></path>
          <path d="M284.932 358.551a4.22 4.22 0 00-.266 1.651c.102 2.302 2.054 4.058 4.356 3.955 11.785-.539 23.603-.739 35.397-1.012 19.513-.45 39.031-.753 58.549-.933a3.95 3.95 0 003.919-3.981 3.985 3.985 0 00-4.008-3.934c-18.274.15-36.529.305-54.802.568-13.137.19-26.287.396-39.413.969a4.182 4.182 0 00-3.732 2.717z"></path>
        </g>
      </g>
    </svg>
  );
}

export default HouseOfCardsLogo;